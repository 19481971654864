import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import AboutEntrestoContent from '../components/about-entresto/about'
const AboutEntresto = props => (
  <Layout location={props.location}>
    <SEO
      title="About Pulse Care Program and Entresto"
      keywords={[
        `novartis`,
        `medgrocer`,
        `pulse care`,
        `entresto`,
        `sacubitril-valsartan`,
      ]}
    />
    <AboutEntrestoContent />
  </Layout>
)

export default AboutEntresto
