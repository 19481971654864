import React, { Fragment } from 'react'
import Img from 'gatsby-image'
import { StaticQuery } from 'gatsby'
import ContentFold from '../page-content/content-fold'
import classNames from 'classnames'
import styles from './about-entresto.module.scss'

const ListSection = ({ title, subtitle, children }) => (
  <Fragment>
    <h2 className={classNames('has-text-weight-bold')}>{title}</h2>
    <div className={classNames()}>{subtitle}</div>
    <br />
    <ul className={classNames(styles.ulStyle)}>
      <div className={classNames('pl-3')}>{children}</div>
    </ul>
  </Fragment>
)
const AboutEntrestoContent = ({ data }) => (
  <ContentFold title="" vcentered>
    <div className={classNames('py-2')}>
      <div className={classNames(styles.boxHeader, 'button')}>
        Sacubitril-Valsartan
      </div>
      <br />
      <Img fluid={data.entrestoLogo.childImageSharp.fluid} />
    </div>
    <p>
      <span className={classNames('entresto-bold')}>Entresto™</span> is used to
      treat certain types of heart failure. It contains sacubitril and valsartan
      which work by expanding blood vessels to make it easier for your heart to
      pump blood to your body. It also helps the body get rid of excess salt and
      water to prevent further increase in the size of the heart.
    </p>
    <br />
    <ListSection title="How to Use">
      <li>Take Entresto as directed by your doctor, usually twice daily.</li>
      <li>You may take it with or without food.</li>
      <li>
        If you miss a dose, skip your missed dose and continue with your regular
        schedule. Do not take a double dose to make up for a missed one.
      </li>
    </ListSection>
    <br />
    <ListSection
      title="Side Effects"
      subtitle="Check with your doctor if the following side effects occur:"
    >
      <li>
        Kidney problem symptoms: decreased urination, nausea, vomiting, swelling
        of the feet
      </li>
      <li>Low blood pressure symptoms: dizziness, lightheadedness, fainting</li>
      <li>
        Signs of high potassium levels: irregular heartbeat, muscle weakness
      </li>
    </ListSection>
    <br />
    <ListSection title="Do not take this medication if you:">
      <li>
        Are allergic to Sacubitril, Valsartan, or any ingredients of the
        medication
      </li>
      <li>Are taking any medication containing ACE inhibitors or aliskiren</li>
      <li>
        Have history of hereditary angioedema (abnormal swelling of the face and
        throat)
      </li>
      <li>Have moderate to severe kidney and liver disease</li>
      <li>Are pregnant or planning to get pregnant</li>
    </ListSection>
    <br />
    This information is intended to supplement, not substitute, the expertise of
    your healthcare professional.
  </ContentFold>
)

const AboutEntrestoPage = () => (
  <StaticQuery
    query={graphql`
      query {
        entrestoLogo: file(relativePath: { eq: "entresto-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <AboutEntrestoContent data={data} />}
  />
)

export default AboutEntrestoPage
